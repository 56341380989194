.layout {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}
.centercontainer {
  max-width: 90rem;
  width: 100%;
  margin: 0 auto;
}
