.configurator-block {
  max-width: 50rem;
}

.multisiteForm {
  &__counter {
    text-align: center;
    color: var(--clr-grey);
    font-size: 1.25rem;
    span {
      color: var(--clr-primary);
    }
  }

  &__content {
    &--title {
      --font-weight: 700;
      font-size: var(--fs-400);
      text-align: center;
      margin-block-start: 1rem;
      margin-block-end: 1rem;
    }

    .styledSelect {
      position: relative;

      &::after {
        border-style: solid;
        border-width: 0.2rem 0.2rem 0 0;
        border-color: var(--clr-primary);
        content: '';
        display: inline-block;
        top: 1px;
        right: 15px;
        vertical-align: top;
        height: 1rem;
        width: 1rem;
        rotate: 135deg;
        position: absolute;
      }
    }

    select {
      border: 3px solid;
      border-radius: 0.625rem;
      border-color: var(--clr-primary);
      padding-inline: 1.5rem;
      padding-block: 1rem;
      appearance: none;
      width: 100%;
    }
  }

  &__footer {
    margin-block-start: 4rem;
    display: flex;
    justify-content: space-evenly;
    gap: 1.25rem;

    &.rev {
      flex-direction: row-reverse;
    }
    .button {
      min-width: inherit;
      margin: 0;
      max-width: 18rem;
      width: 100%;
    }
  }
}

progress {
  appearance: none;
  border-radius: 0;
  height: 4px;
  accent-color: var(--clr-primary);
}
::-webkit-progress-bar {
  background-color: var(--clr-grey);
}
::-webkit-progress-value {
  background-color: var(--clr-primary);
}
::-moz-progress-bar {
  background-color: var(--clr-primary);
}

.styledRadio {
  .form-control {
    display: grid;
    grid-template-columns: 1em auto;
    gap: 1rem;
    line-height: 1;
    color: var(--clr-primary);
  }
  input[type='radio'] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid var(--clr-accent);
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    &::before {
      content: '';
      width: 0.65em;
      height: 0.65em;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em var(--clr-accent);
      // Windows High Contrast Mode
      background-color: CanvasText;
    }
    &:checked::before {
      transform: scale(1);
    }
    &:focus-visible {
      outline: max(2px, 0.15em) solid currentColor;
      outline-offset: max(2px, 0.15em);
    }
    &:focus-within {
      color: var(--clr-accent);
    }
  }

  .form-control + .form-control {
    margin-top: 1em;
  }
}

.result {
  --font-weight: 700;
  font-size: 48px;
  color: var(--clr-primary);
}

.price {
  --font-weight: 700;
  color: var(--clr-secondary);
  font-size: 30px;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
}
