@use '../abstracts/mixins' as *;

.cp-wrapper {
  margin-top: 3rem;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
  column-gap: 1rem;
  row-gap: 5rem;

  @include mq(tabletup) {
    grid-template-columns: repeat(auto-fit, minmax(14rem, 16rem));
  }
}

.contact-persons {
  max-width: 50rem;

  .cs + .cs {
    margin-block-start: 6rem;
  }
}

.cp {
  display: flex;
  flex-direction: column;

  img {
    border: 1px solid var(--clr-neutral);
  }

  &__name {
    margin-block-start: 2rem;
  }

  &__pos {
    margin-block-start: 0.2rem;
    font-size: 24px;
  }

  &__ph {
    --font-weight: 700;
    margin-block-start: 2rem;
    margin-block-end: 2rem;
  }

  .button {
    max-width: 12rem;
    max-height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-left: 0;

    &:hover svg path {
      stroke: var(--clr-white);
    }
  }
}
