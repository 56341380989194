@use 'breakpoints' as *;

// use:  @include mq(small) {...

@mixin mq($key, $type: min) {
  $size: map-get($breakpoints, $key);

  @media (#{$type}-width: $size) {
    @content;
  }
}

@mixin max-mq($key, $type: max) {
  $size: map-get($breakpoints, $key);

  @media (#{$type}-width: $size) {
    @content;
  }
}
