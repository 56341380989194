@use '../../utilities/container';

.form {
  @extend .container !optional;

  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: stretch;
  max-width: 50rem;

  &__submit {
    width: max-content;
    margin-inline: auto;
  }

  > div {
    position: relative;
  }

  > div:has(*[required]):after {
    content: '*';
    display: block;
    height: 10px;
    width: 10px;
    color: var(--clr-secondary);
    font-size: 20px;
    position: absolute;
    left: -11px;
    top: 0;
  }
}
