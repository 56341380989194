@use '../abstracts/mixins' as *;

.image-block {
  display: flex;
  flex-direction: column;
  align-items: center;

  .image-block-wrapper {
    position: relative;
    height: 286px;
    width: 100%;

    @include mq(medium) {
      height: auto;
      width: 50rem;
      aspect-ratio: 5/3;
    }
  }

  img {
    margin-inline-start: auto;
    margin-inline-end: auto;
    border-radius: var(--border-radius);
    object-fit: cover;
  }

  .caption {
    --font-weight: 400;

    width: 100%;
    margin-block-start: 0.5rem;
    margin-block-end: 2rem;
    font-size: 1.125rem;
    text-align: center;

    @include mq(medium) {
      width: 50rem;
    }
  }
}
