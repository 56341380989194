@use '../abstracts' as *;

:root {
  --section-spacing: 3.5rem;
  --border-radius: 0.25rem;
  accent-color: var(--clr-accent);
  @include mq(medium) {
    --section-spacing: 7rem;
  }
  @each $color, $value in $colors {
    --clr-#{$color}: #{$value};
  }

  @each $screen-size, $property in $type-scale {
    @if $screen-size == small {
      @each $prop, $value in $property {
        --fs-#{$prop}: #{$value};
      }
    } @else {
      @include mq(small) {
        @each $prop, $value in $property {
          --fs-#{$prop}: #{$value};
        }
      }
    }
  }
}
