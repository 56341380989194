.footer {
  &__menu-list {
    list-style: none;
    padding-inline-start: 0;

    a {
      --font-weight: 600;
      line-height: 2rem;
      text-decoration: none;
    }
  }

  .socialicon {
    object-fit: contain;
    display: inline-block;
  }
}
