@use '../abstracts/mixins' as *;

.container {
  --spacer: 2rem;
  width: min(100% - 3.125rem, 1200px);
  margin-left: auto;
  margin-right: auto;
  // margin-top: var(--spacer);
  // margin-bottom: calc(var(--spacer) * 2);
  margin-block-start: 3.9rem;
  margin-block-end: 4rem;
  padding: 0;
}

@include mq(big) {
  .container {
    --spacer: 2rem;
    padding: 0 9.375rem;
  }
}
