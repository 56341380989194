* {
  // this is a bad idea, but works for now
  font-variation-settings: 'wght' var(--font-weight, 400), 'wdth' var(--font-width, 80);
}

body {
  font-size: var(--fs-400);
  color: var(--clr-neutral);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  --font-weight: 700;
  line-height: 1.1;
  color: var(--clr-primary);
}

h1,
.h1 {
  font-size: var(--fs-800);
  color: var(--clr-neutral);
  margin-bottom: 2.125rem;
}

h2,
.h2 {
  --flow-spacer: 2rem;
  font-size: var(--fs-700);
  margin-bottom: var(--flow-spacer);
}

h3,
.h3 {
  --flow-spacer: 2rem;
  font-size: var(--fs-600);
}

h4,
.h4 {
  font-size: var(--fs-500);
}

small,
.text-small {
  font-size: var(--fs-300);
}

strong {
  --font-weight: 600;
}

a:not(.button) {
  color: var(--clr-primary);
  position: relative;
  &:hover,
  &:focus {
    color: var(--clr-primary);
    text-decoration: none;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 0.1em;
    background-color: var(--clr-primary);
    opacity: 0;
    transform: scale(0);
    transition: opacity 300ms, transform 300ms;
  }

  &:hover,
  &:focus,
  &.active {
    &:after {
      opacity: 1;
      transform: scale(1);
      transform-origin: center;
    }
  }
}

// color helper
.clr-b {
  color: var(--clr-neutral);
}

.clr-w {
  color: var(--clr-white);
}

.clr-pr {
  color: var(--clr-primary);
}

.clr-sc {
  color: var(--clr-secondary);
}
