@use '../abstracts/mixins' as *;

.nav {
  @include max-mq(tabletdown) {
    // display: flex;
    position: absolute;
    top: -100%;
    height: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &--open {
    --nav-open-top: 5.7rem;

    height: 100vh;
    opacity: 0.95;
    border-top: 2px solid var(--clr-primary);
    background: var(--clr-lightblue);
    position: fixed;
    height: calc(100% - var(--nav-open-top));
    width: 100%;
    top: var(--nav-open-top);
    bottom: 0;
    opacity: 1;
    overflow-y: scroll;
  }

  &__list {
    @include mq(desktopup) {
      display: flex;
      // gap: 2.5rem;
    }

    @include mq(tabletdown) {
      line-height: 3rem;
    }

    @include max-mq(tabletdown) {
      padding-inline-start: 0;
      margin-block-start: 0;
    }

    &-item {
      position: relative;
      padding-inline-start: 1rem;
      padding-inline-end: 1rem;

      @include max-mq(tabletdown) {
        padding-block-start: 1.5rem;
        padding-block-end: 1.5rem;
        border-bottom: 2px solid var(--clr-primary);

        &:last-of-type {
          border-bottom: none;
        }
      }

      a {
        --font-weight: 700;

        text-decoration: none;
        display: inline-block;

        @include max-mq(tabletdown) {
          font-size: var(--fs-500);
        }
      }

      .nav__sub-menu a {
        @include max-mq(tabletdown) {
          --font-weight: 400;
        }
      }

      &:hover .nav__sub-menu {
        @include mq(desktopup) {
          height: fit-content;
          width: fit-content;
          opacity: 1;
          overflow: visible;
        }
      }
    }
  }

  &__sub-menu {
    @include mq(desktopup) {
      position: absolute;
      left: 0;
      top: 51px;
      width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;
      transition: all ease 0.2s;
      height: 0;
      opacity: 0;
      overflow: hidden;
      background-color: #fff;

      .nav__link {
        white-space: nowrap;
        padding: 1rem 0;

        &:after {
          bottom: 15px;
        }
      }
    }
    @include max-mq(tabletdown) {
      padding-inline-start: 0;
    }
  }

  &__link {
    font-size: 1.125rem;

    @include max-mq(tabletdown) {
      &:before {
        content: '';
        display: inline-block;
        transition: all ease 0.3s;
      }

      &:hover,
      &.active,
      &:focus-visible {
        &:before {
          content: '';
          display: inline-block;
          background: var(--clr-primary);
          height: 30px;
          width: 5px;
          position: absolute;
          left: -11px;
          top: 2px;
        }

        &:after {
          display: none;
        }
      }
    }
  }
}

@include mq(desktopup) {
  .nav__list-item:last-of-type {
    .nav__sub-menu {
      right: 0;
      left: inherit;
    }
  }
}
