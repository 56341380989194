.accordions {
  &__header {
    text-align: center;
    max-width: 50rem;
    margin-inline: auto;
  }

  .flow {
    max-width: 50rem;
    margin-inline: auto;
  }

  .accordion {
    &__header {
      color: var(--clr-white);

      button {
        --font-weight: 400;
        font-size: var(--fs-400);
      }
    }

    &__content {
      background-color: var(--clr-white);
      padding-inline: 1.5rem;
      padding-block-start: 1rem;
    }
  }
}
