.accordion {
  --flow-spacer: 1.5rem;
  --padding: 1.5rem;

  overflow: hidden;
  background: var(--clr-primary);
  border-radius: var(--border-radius);

  &__header {
    all: unset;
    display: flex;
  }

  &__trigger {
    all: unset;
    background-color: transparent;
    padding: var(--padding);
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--fs-500);
    font-weight: 600;
    line-height: 1.3;
    cursor: pointer;
  }

  &__icon {
    color: var(--clr-white);
    width: 2.75rem;
    height: 2.75rem;
    transition: transform 200ms linear;
    flex-shrink: 0;
    margin-inline-start: 1rem;

    [data-state='open'] & {
      transform: rotate(-180deg);
    }
  }

  &__content {
    overflow: hidden;
    font-size: var(--fs-400);

    &[data-state='open'] {
      animation: slideDown 100ms linear forwards;
    }

    &[data-state='closed'] {
      animation: slideUp 100ms linear forwards;
    }
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}
