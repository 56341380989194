.image {
  --spacer: 7.5rem;
  padding: 0;
  margin: var(--spacer) auto 0;

  & + * {
    margin-block-start: -1rem;
    padding-block-start: 5rem;
  }

  &--full-width {
    width: auto;
  }

  &--full-width &__element {
    border-radius: 0;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }

  &__element {
    border-radius: var(--border-radius);
  }
}
