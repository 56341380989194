@use '../abstracts/mixins' as *;

.button {
  --bg-color: var(--clr-primary);
  --color: var(--clr-white);
  --border-radius: 1.25rem;
  --font-weight: 630;

  color: var(--color);
  background-color: var(--bg-color);
  font-size: 1.125rem;
  padding: 0.8em 2em;
  border-radius: var(--border-radius, 20px);
  border: none;
  cursor: pointer;
  transition: box-shadow ease 0.2s, background-color ease 0.2s;
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  text-align: center;
  width: 100%;

  @include mq(medium) {
    max-width: 20.3125rem;
  }

  &:focus-visible {
    outline: 2px solid var(--border-color);
    outline-offset: 2px;
  }

  &:hover {
    box-shadow: inset 0px 0 6px 2px rgba(0, 0, 0, 0.3);
    --bg-color: var(--clr-primary-darken);
  }

  &--primary {
    --bg-color: var(--clr-primary);
    --color: var(--clr-white);

    &:hover {
      color: var(--clr-white);
    }
  }

  &--secondary {
    --bg-color: var(--clr-secondary);
    --border-color: var(--clr-secondary);
    --color: var(--clr-white);
  }
}

.button-thin {
  --bg-color: var(--clr-white);
  --border-color: var(--clr-primary);
  --color: var(--clr-primary);
  border: 2px solid var(--border-color);
  &:hover {
    --color: var(--clr-white);
  }
}
