@media print {
  .hero {
    background-image: none;
    display: block;
    min-height: auto !important;
  }

  .footer,
  .splide,
  button,
  .button,
  #main-nav .hero::after {
    display: none !important;
  }

  h1 {
    font-size: 100%;
  }

  .header {
    position: relative !important;
  }
  .header.scroll {
    box-shadow: none !important;
    background-color: transparent;
  }
  .centercontainer {
    padding-top: 100px;
  }
  .header .centercontainer {
    align-items: center !important;
    justify-content: center !important;
  }
}
