@use '../abstracts/mixins' as *;
@use '../utilities/container';

.hero {
  --flow-spacer: 4rem;
  --spacer: 0;

  @extend .container !optional;
  margin-block-start: 0;
  background-image: url(../assets/bgheader-fake-mobile.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
  min-height: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin-bottom: 3rem;

  .container {
    max-width: 50rem;
  }

  &__title {
    text-align: center;
  }

  &__icon {
    text-align: center;
    svg {
      height: 100px;
      width: auto;
    }
  }

  &__subtitle {
    --flow-spacer: 1.5rem;
    --font-weight: 700;
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
    max-width: 32rem;
    margin-inline-start: auto;
    margin-inline-end: auto;
  }

  &__text {
    font-size: var(--fs-500);
    text-align: center;
    margin-inline: auto;
  }
  &::before {
    @include mq(medium) {
      content: '';
      display: block;
      width: 100%;
      height: 190px;
      background: #d3e2ee;
      position: absolute;
      top: 0;
      z-index: -1;
    }
  }
  &::after {
    clip-path: polygon(64% 84%, 0% 100%, 130% 100%);
    content: '';
    position: absolute;
    width: 100%;
    height: 270px;
    bottom: 8px;
    background-color: #fff;
    background-position: top;
    @media (min-width: 440px) {
      bottom: -1px;
    }
  }
}

@include mq(medium) {
  .hero {
    background-image: url(../assets/bgheader-fake.svg);
    // min-height: 400px;
    min-height: 19.375rem;
    justify-content: flex-start;

    &__title {
      text-align: left;
    }

    &__text {
      max-width: 60ch;
    }

    &__subtitle {
      --flow-spacer: 1rem;
      text-align: left;
      max-width: 50rem;
      padding-inline-start: 0;
    }

    > .container {
      padding-block-end: 3.5rem;
    }

    &::after {
      clip-path: polygon(64% 161px, 0% 100%, 130% 100%);
    }
  }
}
