@use '../abstracts/mixins' as *;

.contactpopup {
  z-index: 3;
  right: 5.5rem;
  top: -5.5rem;
  position: fixed;

  @include mq(tabletup) {
    right: 0.5rem;
    top: 4rem;
  }

  button {
    border-radius: 5rem;
    height: 4.5rem;
    width: 4.5rem;
    display: block;
    padding: 1.1rem;
    border: none;
    background-color: var(--clr-secondary);
    position: absolute;
    right: 30px;
    bottom: -170px;
    z-index: 1;
    outline: 3px solid transparent;
    outline-offset: 0;
    transition: outline ease 0.2s;

    @include mq(tabletup) {
      box-shadow: 0 0.25rem 1.5rem rgba(174, 16, 10, 0.8);
      height: 5rem;
      width: 5rem;
    }

    &:focus-visible {
      outline: 3px solid var(--clr-secondary);
      outline-offset: 5px;
    }

    &[data-state='open'] {
      background-color: #fff;

      svg {
        path,
        rect,
        circle {
          stroke: var(--clr-secondary);
        }
      }
    }
  }
}

.PopoverContent {
  border-radius: 4px;
  padding: 20px;
  background-color: white;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  width: calc(100vw - 2.5rem);
  max-width: 60rem;
  z-index: 1;
  position: relative;

  &:focus {
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px,
      0 0 0 2px var(--violet7);
  }

  &[data-state='open'][data-side='top'] {
    animation-name: slideDownAndFade;
  }

  &[data-state='open'][data-side='right'] {
    animation-name: slideLeftAndFade;
  }

  &[data-state='open'][data-side='bottom'] {
    animation-name: slideUpAndFade;
  }

  &[data-state='open'][data-side='left'] {
    animation-name: slideRightAndFade;
  }

  .button {
    min-width: inherit;
    width: 100%;
  }

  .callbackText {
    margin-block-start: 1rem;
    margin-block-end: 1rem;
  }

  ///
  /// test
  ///
  @include mq(desktopup) {
    max-width: 37rem;
  }

  form {
    width: 100%;
    gap: 1.4rem;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
  }

  .input__element {
    padding: 0.5rem;
  }
  .label,
  .callbackText {
    font-size: clamp(1rem, 4vw, 1.2rem);
  }
}

.PopoverArrow {
  fill: white;
  height: 18px;
  width: 19px;
}

.PopoverClose {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 1rem;
  height: 4rem;
  width: 4rem;
  border: none;
  background-color: transparent;

  svg {
    height: 2rem;
    width: 2rem;
  }

  &:hover {
    background-color: var(--violet4);
  }

  &:focus {
    box-shadow: 0 0 0 2px var(--violet7);
  }
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
