@use '../abstracts/mixins' as *;
.streamfield .splide {
  &__track {
    z-index: -1;
  }

  &__arrow {
    z-index: inherit;
    background-color: var(--clr-primary);

    svg {
      fill: #fff;
    }
  }
}
