.todolist {
  display: grid;
  background-color: var(--clr-primary);
  color: #fff;
  --spacer: 4rem;

  &__icon {
    filter: grayscale(100) contrast(0) brightness(100);
    margin-block-end: 2.5rem;
    text-align: center;

    svg {
      height: 90px;
      width: 90px;
    }
  }

  .flow {
    margin-block-end: 3.75rem;
  }

  .styledcheckbox {
    display: grid;
    grid-template-columns: 1em auto;
    gap: 2rem;
    margin-bottom: 1.5rem;

    input[type='checkbox'] {
      appearance: none;
      width: 2rem;
      height: 2rem;
      border: 0.15rem solid var(--clr-white);
      border-radius: 0.4rem;
      top: -3px;
      position: relative;
      display: grid;

      &::before {
        content: '✓';
        width: 0.65em;
        height: 0.65em;
        transform: scale(0);
        transform-origin: bottom;
        transition: 120ms transform ease-in-out;
        background-color: transparent;
        color: var(--clr-white);
        font-size: 40px;
        top: -19px;
        position: relative;
        text-shadow: 1px 1px 2px #f8f8f8;
      }

      &:checked::before {
        transform: scale(1.5);
      }
    }
  }
}
