.text-block.contactblock {
  --bg-clr: var(--clr-secondary);

  color: var(--clr-white);
  text-align: center;
  padding-block-start: 3.5rem;
  box-shadow: 0 0 0 100vmax var(--clr-secondary);
  clip-path: inset(0 -100vmax);

  .icon {
    margin-inline-start: auto;
    margin-inline-end: auto;
    margin-block-end: 2rem;
  }

  h2 {
    color: var(--clr-white);
  }

  .button {
    --bg-color: var(--clr-white);
    color: var(--clr-secondary);
    margin-block: 1rem;
  }
}
