.header {
  position: sticky;
  top: 0;
  z-index: 3;
  background: var(--clr-white);
  transition: box-shadow ease 0.2s;

  .centercontainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;

    &__link {
      display: inline-block;
      padding: 1.5rem;
    }
  }
  &.scroll {
    box-shadow: 0 0 1rem rgb(0 0 0 /0.2);
  }

  &__logo {
    // height: 2.6rem;
    // position: relative;
    // top: 0.3125rem;
    // object-fit: contain;
    &:after {
      display: none;
    }
  }
}
