@use '../abstracts/mixins' as *;

.text-block {
  --bg-clr: var(--clr-white);
  --spacer: 4rem;
  color: var(--textclr);
  width: 100%;

  background-color: var(--bg-clr);

  padding-inline-start: 1.5rem;
  padding-inline-end: 1.5rem;

  padding-block-start: 3rem;
  padding-block-end: 3rem;

  .content {
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
  }

  .text-center {
    @include mq(medium) {
      text-align: center;
    }
  }

  h2 {
    --flow-spacer: 1.875rem;
    --font-weight: 700;
    color: var(--textclr);
  }

  .button {
    margin-block-start: 3rem;
  }

  &__icon {
    margin-inline: auto;
    margin-block-end: 2.5rem;
  }

  &.bg-blue {
    --bg-clr: var(--clr-primary);
    padding-block-start: 7.5rem;
    padding-block-end: 7.5rem;

    .button {
      --bg-color: var(--clr-white);
      --color: var(--clr-primary);
    }
  }

  &.bg-red {
    --bg-clr: var(--clr-secondary);

    .button {
      --bg-color: var(--clr-white);
      color: var(--clr-secondary);
    }

    a,
    a:hover {
      color: #fff;
    }
  }

  &.clr-white,
  &.clr-white h2 {
    --textclr: var(--clr-white);
  }

  &.clr-white .text-block__icon {
    filter: grayscale(100) contrast(0) brightness(100);
  }

  &.clr-black {
    --textclr: var(--clr-neutral);

    h2 {
      --textclr: var(--clr-primary);
    }
  }

  &.clr-red,
  &.clr-red h2 {
    --textclr: var(--clr-secondary);
  }

  &--small-width {
    max-width: min(100% - 2rem, 50rem);
    padding: clamp(1.25rem, 5vw, 3rem) !important;
    padding-block-start: 3rem;
    padding-block-end: 3rem;
    padding-inline-start: 3rem;
    padding-inline-end: 3rem;

    &.text-block--with-icon {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      justify-content: center;
      align-items: center;

      .text-block__icon {
        margin: 0;
      }

      .content {
        font-size: var(--fs-600);

        h2 {
          margin-block-end: 0;
        }
      }
    }

    .content {
      margin-left: 0 !important;
      margin-right: 0 !important;

      ul {
        padding: 0;
        padding-inline-start: 1rem;
      }

      li {
        padding-inline-start: 1rem;
      }

      li + li {
        margin-block-start: 1rem;
      }

      li::marker {
        content: url('/checkmark.svg');
      }
    }
  }
}

// smaller margin if field before is image-block
.streamfield.image-block + .streamfield.text-block {
  margin-top: -3.75rem;
}

.streamfield.text-block.bg-red + .streamfield.text-block.bg-red {
  margin-block-start: -4rem;

  .button {
    background: inherit;
    color: #fff;
    border-color: #fff;
    border: 2px solid;
  }
}

@include mq(small) {
  .text-block {
    &--small-width {
      &.text-block--with-icon {
        flex-direction: row;
      }
    }
  }
}
