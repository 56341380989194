@use '../abstracts/mixins' as *;
.hamburger {
  display: none;
}
@include max-mq(tabletdown) {
  .hamburger {
    display: block;
    --hb-color: var(--clr-primary);

    border: none;
    background: transparent;

    .line {
      transition: transform 300ms ease-in 300ms, rotate 300ms ease-in, opacity 0ms 300ms;
      transform-origin: center;
    }
  }

  .nav--open + .hamburger {
    .line {
      transition: transform 300ms ease-in, rotate 300ms ease-in 300ms, opacity 0ms 300ms;

      &__top {
        rotate: 45deg;
        transform: translateY(20px);
      }

      &__middle {
        opacity: 0;
      }

      &__bottom {
        rotate: -45deg;
        transform: translateY(-20px);
      }
    }
  }
}
